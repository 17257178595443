import { t } from "@lingui/macro";
import {
  CapacityAllocation,
  CapacityAllocationItem,
  CopyCapacityAllocationItemDocument,
  CopyCapacityAllocationItemMutation,
  CopyCapacityAllocationItemMutationVariables,
  IsDateBeforeLastPlanningUpdateDocument,
  IsDateBeforeLastPlanningUpdateMutation,
  IsDateBeforeLastPlanningUpdateMutationVariables,
  IsUserInTaskDocument,
  IsUserInTaskQuery,
  IsUserInTaskQueryVariables,
  MoveCapacityAllocationItemDocument,
  MoveCapacityAllocationItemInput,
  MoveCapacityAllocationItemMutation,
  MoveCapacityAllocationItemMutationVariables,
  PlanningDailyViewWhereColumn,
  PlanningFilterOptionsQuery,
  ReorderCapacityAllocationUserItemsDocument,
  ReorderCapacityAllocationUserItemsMutation,
  ReorderCapacityAllocationUserItemsMutationVariables,
  Scalars,
  SqlOperator,
  User,
} from "@src/__generated__/urql-graphql";
import {
  PlanningDailyViewItemModel,
  PlanningDailyViewUserByDayModel,
  PlanningDailyViewUserDayModel,
} from "@src/components/modules/resource-planning/timeline/models";
import {
  EMPTY_FILTERS_HEIGHT,
  USED_FILTERS_HEIGHT,
  getCommonCapacityAllocationTypeOptions,
} from "@src/constants/planning";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { PlanningViewTypeEnum } from "@src/stores/models/WorkspaceSettings";
import { DragItem, MovedAllocationPayload } from "@src/types/planning";
import {
  commonQueryVariables,
  commonSearchParams,
} from "@src/utils/apolloHelpers";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { BooleanState } from "@src/utils/mobx/states/BooleanState";
import { CSSUnitValue } from "@src/utils/types";
import {
  addDays,
  eachDayOfInterval,
  endOfWeek,
  isBefore,
  isSameDay,
  startOfWeek,
} from "date-fns";
import { action, computed, makeObservable, observable } from "mobx";

export type ResourcePlanningFetchDataArgs = {
  backgroundUpdate: boolean;
  loadForAllUsers: boolean;
};

export class ResourcePlanningViewStore implements BaseStore {
  appStore: AppStore;
  @observable selectedDate = new Date();
  @observable data: PlanningDailyViewUserByDayModel[] = [];
  @observable.ref clonedData: PlanningDailyViewUserByDayModel[] | undefined =
    undefined;
  isFetching = new BooleanState(false);
  @observable searchTerm = "";
  @observable active: DragItem | undefined = undefined;
  @observable movedAllocationPayload: MovedAllocationPayload | undefined =
    undefined;
  @observable lastSyncedAt: Date | null | undefined = undefined;
  @observable syncingIds: PlanningDailyViewItemModel["id"][] = [];

  where: Filters<PlanningDailyViewWhereColumn>;

  _fetchData: (loadForAllUsers: boolean) => Promise<any>;

  constructor(
    appStore: AppStore,
    fetchData: (loadForAllUsers: boolean) => Promise<any>,
    onFilterChange?: () => void,
  ) {
    makeObservable(this);
    this._fetchData = fetchData;
    this.appStore = appStore;
    this.where = new Filters(
      [
        new Filter({
          column: PlanningDailyViewWhereColumn.TeamId,
          operator: SqlOperator.In,
          title: t`Team`,
          options: [],
          hidden: true,
        }),
        new Filter({
          column: PlanningDailyViewWhereColumn.TimeTrackingWorkTypeId,
          operator: SqlOperator.In,
          title: t`Position`,
          options: [],
        }),
        new Filter({
          column: PlanningDailyViewWhereColumn.AllocableType,
          operator: SqlOperator.In,
          title: t`Type`,
          options: getCommonCapacityAllocationTypeOptions(),
        }),
        new Filter({
          column: PlanningDailyViewWhereColumn.TaskPriorityId,
          operator: SqlOperator.In,
          title: t`Priority`,
          options: [],
          isCollapsed: true,
        }),
        new Filter({
          column: PlanningDailyViewWhereColumn.TaskStatusId,
          operator: SqlOperator.In,
          title: t`Task status`,
          options: [],
          isCollapsed: true,
        }),
        new Filter({
          column: PlanningDailyViewWhereColumn.BrandId,
          operator: SqlOperator.In,
          title: t`Client/Brand`,
          tagTitle: t`Brand`,
          options: [],
          sectioned: true,
          hasSelectAllOption: true,
          isCollapsed: true,
        }),
        new Filter({
          column: PlanningDailyViewWhereColumn.UserId,
          title: t`User`,
          operator: SqlOperator.In,
          options: [],
          isCollapsed: true,
        }),
        new Filter({
          column: PlanningDailyViewWhereColumn.CreatedByUserId,
          operator: SqlOperator.In,
          title: t`Creator`,
          options: [],
          isCollapsed: true,
        }),
      ],
      {
        onChange: () => {
          onFilterChange?.();
          this.fetchDailyView();
        },
      },
    );
  }

  @computed get searchParams() {
    return commonSearchParams(this);
  }
  @computed get queryParams() {
    return commonQueryVariables(this);
  }

  @computed get selectedDateRange(): [Date, Date] {
    if (
      this.appStore.workspaceStore.settings?.default_planning_view_settings
        .view_type === PlanningViewTypeEnum.WEEK_FROM_NOW
    ) {
      return [this.selectedDate, addDays(this.selectedDate, 6)];
    } else {
      const opts = {
        weekStartsOn: this.appStore.workspaceStore.settings?.startOfWeekNumber,
      };
      return [
        startOfWeek(this.selectedDate, opts),
        endOfWeek(this.selectedDate, opts),
      ];
    }
  }

  @computed get daysOfSelectedInterval(): Date[] {
    const [start, end] = this.selectedDateRange;
    return eachDayOfInterval({
      start,
      end,
    });
  }

  @computed get filterRowHeight(): CSSUnitValue {
    const filtersUsed = this.where.filters.some(({ column, value, hidden }) => {
      if (column === PlanningDailyViewWhereColumn.UserId && hidden)
        return false;
      return value.length;
    });

    return filtersUsed ? USED_FILTERS_HEIGHT : EMPTY_FILTERS_HEIGHT;
  }

  @action setFilterOptions(data: PlanningFilterOptionsQuery) {
    this.where.filtersByColumn
      .get(PlanningDailyViewWhereColumn.TaskStatusId)
      ?.setOptions(
        data.taskStatuses.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      );

    this.where.filtersByColumn
      .get(PlanningDailyViewWhereColumn.TaskPriorityId)
      ?.setOptions(
        data.taskPriorities.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      );

    this.where.filtersByColumn
      .get(PlanningDailyViewWhereColumn.BrandId)
      ?.setOptions(
        data.clientsSimpleMap.map((i) => ({
          value: i?.id,
          label: i?.name,
          options: i?.brands.map(({ name, id }) => {
            return {
              label: name,
              value: id,
            };
          })!,
        })),
      );

    this.where.filtersByColumn
      .get(PlanningDailyViewWhereColumn.UserId)
      ?.setOptions(
        data.userSimpleMap.map(({ id, full_name }) => ({
          value: id,
          label: full_name,
        })),
      );

    this.where.filtersByColumn
      .get(PlanningDailyViewWhereColumn.CreatedByUserId)
      ?.setOptions(
        data.userSimpleMap.map(({ id, full_name }) => ({
          value: id,
          label: full_name,
        })),
      );

    this.where.filtersByColumn
      .get(PlanningDailyViewWhereColumn.TimeTrackingWorkTypeId)
      ?.setOptions(
        data.timeTrackingWorkTypes.map(({ id, title }) => ({
          value: id,
          label: title,
        })),
      );

    this.where.filtersByColumn
      .get(PlanningDailyViewWhereColumn.TeamId)
      ?.setOptions(
        data.teamSimpleMap.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      );
  }

  @action.bound async fetchDailyView(
    { backgroundUpdate, loadForAllUsers }: ResourcePlanningFetchDataArgs = {
      backgroundUpdate: false,
      loadForAllUsers: false,
    },
  ) {
    !backgroundUpdate && this.isFetching.on();

    await this._fetchData(loadForAllUsers);

    this.isFetching.off();
  }

  @action.bound async syncData() {
    await this.fetchDailyView({
      backgroundUpdate: true,
      loadForAllUsers: true,
    });
  }

  @action.bound optimisticallyMoveAllocationItem({
    from_date,
    to_date,
    to_user_id,
    from_user_id,
    userItem,
    newIndex,
    oldIndex,
  }: Omit<
    MovedAllocationPayload,
    "current_date" | "current_user_id" | "current_index"
  >) {
    const { current_date, current_user_id } = this.movedAllocationPayload ?? {};
    const fromDateString = from_date.toDateString();
    const toDateString = to_date.toDateString();
    const currentDateString = current_date?.toDateString();

    // If the allocation item is dropped in the same place, do nothing
    if (
      !current_date &&
      !current_user_id &&
      fromDateString === toDateString &&
      from_user_id === to_user_id &&
      oldIndex === newIndex
    )
      return;

    const dataLength = this.data.length;
    for (let i = 0; i < dataLength; i++) {
      const assigneeItem = this.data[i];
      const userId = assigneeItem.user.id;

      if (
        // If user is not relevant to the move, skip
        userId !== from_user_id &&
        userId !== to_user_id &&
        userId !== current_user_id
      ) {
        continue;
      }

      const newAssigneeItems: PlanningDailyViewUserDayModel[] = [];
      const assigneeItemsCount = assigneeItem.items.length;

      for (let j = 0; j < assigneeItemsCount; j++) {
        const day = assigneeItem.items[j];
        const dateString = day.date.toDateString();

        if (
          // If day is not relevant to the move, skip
          dateString !== fromDateString &&
          dateString !== toDateString &&
          dateString !== currentDateString
        ) {
          newAssigneeItems.push(day);
          continue;
        }

        const items: PlanningDailyViewItemModel[] = [];
        const dayItemCount = day.items.length;

        for (let k = 0; k < dayItemCount; k++) {
          const item = day.items[k];
          if (item.id === userItem.id) continue;
          items.push(item);
        }

        // Add or/and reorder item
        if (isSameDay(day.date, to_date) && userId === to_user_id) {
          this.updateCurrentMovedAllocationPayload({
            current_date: to_date,
            current_user_id: to_user_id,
            current_index: newIndex,
          });
          items.splice(newIndex, 0, userItem);
          day.items = items;
        } else {
          // Remove item from old place
          day.items = items;
        }

        newAssigneeItems.push(day);
      }

      assigneeItem.items = newAssigneeItems;
    }
  }

  private validateMove(payload: MovedAllocationPayload) {
    const { to_user_id, from_user_id, from_date, to_date, oldIndex, newIndex } =
      payload;

    const fromDateString = from_date.toDateString();
    const toDateString = to_date.toDateString();
    const movedToSameDay = fromDateString === toDateString;
    const movedToSameUser = from_user_id === to_user_id;
    const movedToSameUserAndDay = movedToSameDay && movedToSameUser;
    const onlyReordered = movedToSameUserAndDay && oldIndex !== newIndex;
    const itemNotMoved = movedToSameUserAndDay && oldIndex === newIndex;

    return {
      onlyReordered,
      itemNotMoved,
      movedToSameDay,
      movedToSameUser,
      movedToSameUserAndDay,
    };
  }

  @action.bound async moveAllocationItem(
    movePayload: MovedAllocationPayload,
    newWorkTypeId?: MoveCapacityAllocationItemInput["new_time_tracking_work_type_id"],
  ) {
    const { id, to_user_id, from_user_id, to_date, userItem } = movePayload;

    const { itemNotMoved, onlyReordered, movedToSameDay, movedToSameUser } =
      this.validateMove(movePayload);

    // If the allocation item is dropped in the same place, do nothing
    if (itemNotMoved) {
      return;
    }

    this.syncingIds.push(userItem.id);

    if (!onlyReordered) {
      const moveRes = await client
        .mutation<
          MoveCapacityAllocationItemMutation,
          MoveCapacityAllocationItemMutationVariables
        >(MoveCapacityAllocationItemDocument, {
          input: {
            id,
            to_user_id,
            from_user_id,
            date: to_date,
            new_time_tracking_work_type_id: newWorkTypeId,
          },
        })
        .toPromise();

      if (moveRes.error) {
        this.syncingIds = this.syncingIds.filter(
          (syncId) => syncId !== userItem.id,
        );
        return this.syncData();
      }

      if (moveRes?.data?.moveCapacityAllocationItem) {
        if (!movedToSameUser || !movedToSameDay) {
          this.appStore.UIStore.toast({
            status: "warning",
            title: t`This allocation will be disconnected from the series and will require separate editing.`,
          });
        }
        this.locallyUpdateItemsAfterMove({
          userItems: moveRes.data.moveCapacityAllocationItem.userItems,
          movePayload,
        });
      }
    }

    this.reorderAllocationItems(movePayload);
  }

  @action.bound async duplicateAllocationItem(
    payload: MovedAllocationPayload,
    newWorkTypeId?: MoveCapacityAllocationItemInput["new_time_tracking_work_type_id"],
  ) {
    const { to_user_id, from_user_id, to_date, userItem, newIndex } = payload;
    const { itemNotMoved, onlyReordered } = this.validateMove(payload);

    if (itemNotMoved || onlyReordered) return;

    const dataCount = this.data.length;
    for (let i = 0; i < dataCount; i++) {
      const item = this.data[i];
      if (!item) continue;
      if (item.user.id !== from_user_id) continue;

      const itemCount = item.items.length;

      for (let l = 0; l < itemCount; l++) {
        const userItem = item.items[l];

        if (userItem.date.toDateString() !== payload.from_date.toDateString())
          continue;

        userItem.items.splice(payload.oldIndex, 0, payload.userItem);
      }
    }

    this.syncingIds.push(userItem.id);

    const copyRes = await client
      .mutation<
        CopyCapacityAllocationItemMutation,
        CopyCapacityAllocationItemMutationVariables
      >(CopyCapacityAllocationItemDocument, {
        input: {
          id: userItem.id,
          to_user_id: to_user_id,
          from_user_id: from_user_id,
          date: to_date,
          new_time_tracking_work_type_id: newWorkTypeId,
        },
      })
      .toPromise();

    if (copyRes.error) {
      this.syncData();
      this.syncingIds = this.syncingIds.filter((id) => id !== userItem.id);
      return;
    }

    const matchingUserItem = this.data.find(
      ({ user }) => user.id === to_user_id,
    );

    if (!matchingUserItem) return;
    const resUserItem = copyRes.data?.copyCapacityAllocationItem;
    if (!resUserItem) return;
    const itemCount = matchingUserItem.items.length;

    for (let i = 0; i < itemCount; i++) {
      const userItem = matchingUserItem.items[i];
      if (userItem.date.toDateString() !== to_date.toDateString()) continue;
      userItem.items.splice(
        newIndex,
        1,
        new PlanningDailyViewItemModel(resUserItem, resUserItem.user),
      );
    }

    this.appStore.UIStore.toast({
      status: "warning",
      title: t`This allocation will be disconnected from the series and will require separate editing.`,
    });

    this.syncingIds = [];
  }

  public async checkIfUserInTask(
    userId: Scalars["ID"]["output"],
    taskId: Scalars["ID"]["output"],
  ) {
    const { data } = await client
      .query<IsUserInTaskQuery, IsUserInTaskQueryVariables>(
        IsUserInTaskDocument,
        {
          userId,
          taskId,
        },
      )
      .toPromise();

    return data?.isUserInTask;
  }

  @action.bound async reorderAllocationItems({
    userItem,
    to_date,
    to_user_id,
  }: MovedAllocationPayload) {
    const ids =
      this.data
        .find((assigneeItem) => assigneeItem.user.id === to_user_id)
        ?.items.find((day) => day.date === to_date)
        ?.items?.map(({ id }) => id) ?? [];

    const reorderRes = await client
      .mutation<
        ReorderCapacityAllocationUserItemsMutation,
        ReorderCapacityAllocationUserItemsMutationVariables
      >(ReorderCapacityAllocationUserItemsDocument, {
        ids,
      })
      .toPromise();

    this.syncingIds = this.syncingIds.filter(
      (syncId) => syncId !== userItem.id,
    );

    if (reorderRes.error) {
      this.syncData();
    }
  }

  @action.bound locallyUpdateItemsAfterMove({
    userItems,
    movePayload,
  }: {
    userItems: MoveCapacityAllocationItemMutation["moveCapacityAllocationItem"]["userItems"];
    movePayload: MovedAllocationPayload;
  }): void {
    const { to_date, to_user_id, userItem } = movePayload;

    userItems.forEach((updatedUserItem) => {
      let foundUserItem = this.findUserItem({
        id: updatedUserItem.id,
        userId: updatedUserItem.user.id,
        date: updatedUserItem.date,
      });

      // If the item was moved, we need to find it in the new place because ID could be changed
      if (
        to_user_id === updatedUserItem.user.id &&
        to_date.toDateString() === updatedUserItem.date.toDateString()
      ) {
        foundUserItem = this.findMovedUserItem({
          id: userItem.id,
          to_user_id,
          to_date,
        });
      }

      if (foundUserItem) {
        foundUserItem.completed = updatedUserItem.completed;
        foundUserItem.user = updatedUserItem.user;
        foundUserItem.time_tracking_work_type_id =
          updatedUserItem.time_tracking_work_type_id;
        foundUserItem.id = updatedUserItem.id;
      }
    });
  }

  async checkIfSyncNeeded() {
    const { data } = await client
      .mutation<
        IsDateBeforeLastPlanningUpdateMutation,
        IsDateBeforeLastPlanningUpdateMutationVariables
      >(IsDateBeforeLastPlanningUpdateDocument, {
        date: this.lastSyncedAt,
      })
      .toPromise();

    if (data?.isDateBeforeLastPlanningUpdate) {
      this.syncData();
    }
  }

  updateMovedAllocationPayload(
    payload: Omit<
      MovedAllocationPayload,
      "current_date" | "current_user_id" | "current_index"
    >,
  ) {
    this.movedAllocationPayload = {
      current_date: this.movedAllocationPayload?.current_date,
      current_user_id: this.movedAllocationPayload?.current_user_id,
      current_index: this.movedAllocationPayload?.current_index,
      to_user_id: payload.to_user_id,
      id: payload.id,
      to_date: payload.to_date,
      newIndex: payload.newIndex,
      oldIndex: payload.oldIndex,
      userItem: payload.userItem,
      from_date: payload.from_date,
      from_user_id: payload.from_user_id,
    };
  }

  updateCurrentMovedAllocationPayload(
    payload: Pick<
      MovedAllocationPayload,
      "current_date" | "current_user_id" | "current_index"
    >,
  ) {
    if (!this.movedAllocationPayload) return;
    this.movedAllocationPayload = {
      current_date: payload.current_date,
      current_index: payload.current_index,
      current_user_id: payload.current_user_id,
      id: this.movedAllocationPayload.id,
      userItem: this.movedAllocationPayload.userItem,
      from_user_id: this.movedAllocationPayload.from_user_id,
      from_date: this.movedAllocationPayload.from_date,
      oldIndex: this.movedAllocationPayload.oldIndex,
      newIndex: this.movedAllocationPayload.newIndex,
      to_date: this.movedAllocationPayload.to_date,
      to_user_id: this.movedAllocationPayload.to_user_id,
    };
  }

  @action.bound revertChanges(): void {
    if (this.clonedData?.length) {
      this.data = this.clonedData;
    }
    this.clonedData = undefined;
  }

  /**
   * @param [deleteItemsAfterDeleteDate=true] default = true
   */
  locallyDeleteCapacityAllocation(
    capacityAllocationId: CapacityAllocation["id"],
    deleteDate?: CapacityAllocationItem["date"],
    deleteItemsAfterDeleteDate = true,
  ) {
    if (deleteDate) {
      this.data.forEach((assigneeItem) => {
        assigneeItem.items.forEach((day) => {
          day.items = day.items.filter(({ stats, date }) => {
            return (
              (deleteItemsAfterDeleteDate
                ? isBefore(date, deleteDate)
                : !isSameDay(date, deleteDate)) ||
              stats?.capacity_allocation_id !== capacityAllocationId
            );
          });
        });
      });
    } else {
      this.data.forEach((assigneeItem) => {
        assigneeItem.items.forEach((day) => {
          day.items = day.items.filter(({ stats }) => {
            return stats?.capacity_allocation_id !== capacityAllocationId;
          });
        });
      });
    }
  }

  locallyDeleteAllocationItem(id: CapacityAllocationItem["id"]) {
    this.data.forEach((assigneeItem) => {
      assigneeItem.items.forEach((day) => {
        day.items = day.items.filter((item) => item.id !== id);
      });
    });
  }

  findUserItem(item: {
    id: PlanningDailyViewItemModel["id"];
    userId: User["id"];
    date: Date;
  }): PlanningDailyViewItemModel | undefined {
    return this.data
      .find((assigneeItem) => assigneeItem.user.id === item.userId)
      ?.items.find(
        (day) => day.date.toDateString() === item.date.toDateString(),
      )
      ?.items.find(({ id }) => id === item.id);
  }

  findMovedUserItem(movedItem: {
    id: PlanningDailyViewItemModel["id"];
    to_user_id: User["id"];
    to_date: Date;
  }): PlanningDailyViewItemModel | undefined {
    return this.data
      .find((assigneeItem) => assigneeItem.user.id === movedItem.to_user_id)
      ?.items.find(
        (day) => day.date.toDateString() === movedItem.to_date.toDateString(),
      )
      ?.items.find(({ id }) => id === movedItem.id);
  }
}
