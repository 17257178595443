import {
  PlanningDailyViewPeopleDocument,
  PlanningDailyViewPeopleQuery,
  PlanningDailyViewPeopleQueryVariables,
  PlanningDailyViewWhereColumn,
} from "@src/__generated__/urql-graphql";
import { PlanningDailyViewUserByDayModel } from "@src/components/modules/resource-planning/timeline/models";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { action, makeObservable } from "mobx";
import { ResourcePlanningViewStore } from "./extendables/ResourcePlanningViewStore";

export class ResourcePlanningWeekStore
  extends ResourcePlanningViewStore
  implements BaseStore
{
  appStore: AppStore;

  constructor(appStore: AppStore) {
    super(appStore, () => this.fetchData());
    makeObservable(this);
    this.appStore = appStore;
    this.where.filtersByColumn.get(PlanningDailyViewWhereColumn.UserId)?.hide();
  }

  @action setUserId(userId: string) {
    this.where.filtersByColumn
      .get(PlanningDailyViewWhereColumn.UserId)!
      .setValue(userId);
  }

  @action.bound private fetchData = async () => {
    // Check if filters include userId filter to prevent fetching all users
    if (
      !this.queryParams.filters.where ||
      this.queryParams.filters.where.AND.length < 2
    )
      return;

    const [from, to] = this.selectedDateRange;
    const { data } = await client
      .query<
        PlanningDailyViewPeopleQuery,
        PlanningDailyViewPeopleQueryVariables
      >(
        PlanningDailyViewPeopleDocument,
        {
          dailyViewFilters: {
            where: this.queryParams.filters.where,
            search: this.queryParams.filters.search,
            first: this.queryParams.first,
            page: this.queryParams.page,
            from,
            to,
          },
        },
        {
          requestPolicy: "network-only",
        },
      )
      .toPromise();

    if (!data) {
      this.data = [];
      return;
    }

    this.lastSyncedAt = data.planningDailyViewPeople.last_updated_at;

    this.data = data.planningDailyViewPeople.data.map(
      (item) => new PlanningDailyViewUserByDayModel(item),
    );
  };
}
