import {
  PlanningDailyViewPeopleDocument,
  PlanningDailyViewPeopleQuery,
  PlanningDailyViewPeopleQueryVariables,
} from "@src/__generated__/urql-graphql";
import { PlanningDailyViewUserByDayModel } from "@src/components/modules/resource-planning/timeline/models";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { action, computed, makeObservable } from "mobx";
import { ResourcePlanningViewStore } from "./extendables/ResourcePlanningViewStore";

export class ResourcePlanningDayStore
  extends ResourcePlanningViewStore
  implements BaseStore
{
  private static perPage = 10;
  appStore: AppStore;
  pagination = new PaginationState("planning-day-pagination", {
    page: 0,
    perPage: ResourcePlanningDayStore.perPage,
  });

  constructor(appStore: AppStore) {
    super(
      appStore,
      (loadForAllUsers) => this.fetchData(loadForAllUsers),
      () => this.reset(),
    );
    makeObservable(this);
    this.appStore = appStore;
  }

  @computed get canLoadMore(): boolean {
    return (
      (this.pagination.total === undefined &&
        this.pagination.currentPage === 0) ||
      this.pagination.hasNextPage ||
      (this.pagination.lastItem ?? 0) < (this.pagination.total ?? 0)
    );
  }

  @computed get loadedUserIds(): string[] {
    return this.data.map(({ user }) => user.id);
  }

  @action.bound reset() {
    this.pagination.resetPage();
    this.pagination.setPerPage(ResourcePlanningDayStore.perPage);
    this.data = [];
  }

  @action.bound private fetchData = async (loadForAllUsers?: boolean) => {
    const [from, to] = this.selectedDateRange;
    const filterBase = {
      from,
      to,
      search: this.queryParams.filters.search,
      first: loadForAllUsers
        ? this.pagination.asParams.page * this.pagination.asParams.first
        : this.pagination.asParams.first,
      page: loadForAllUsers ? 1 : this.pagination.asParams.page + 1,
    };

    const { data } = await client
      .query<
        PlanningDailyViewPeopleQuery,
        PlanningDailyViewPeopleQueryVariables
      >(
        PlanningDailyViewPeopleDocument,
        {
          dailyViewFilters: {
            ...filterBase,
            where: this.queryParams.filters.where!,
          },
        },
        {
          requestPolicy: "network-only",
        },
      )
      .toPromise();

    if (!data) {
      this.data = [];
      return;
    }

    const { planningDailyViewPeople } = data;

    this.lastSyncedAt = data.planningDailyViewPeople.last_updated_at;

    if (loadForAllUsers) {
      this.data = planningDailyViewPeople.data.map(
        (item) => new PlanningDailyViewUserByDayModel(item),
      );
    } else {
      planningDailyViewPeople.data
        .filter((item) => !this.loadedUserIds.includes(item.user.id))
        .forEach((item) => {
          this.data.push(new PlanningDailyViewUserByDayModel(item));
        });
      this.pagination.setFromPaginatorInfo(
        data.planningDailyViewPeople.paginatorInfo,
      );
    }
  };
}
