import {
  DefaultContext,
  MutationOptions,
  OperationVariables,
  TypedDocumentNode,
} from "@apollo/client";
import { client } from "@src/services/apollo-client";
import { GraphQLError } from "graphql";
import { action, makeObservable } from "mobx";
import { BooleanState } from "../../utils/mobx/states/BooleanState";

export class MutationHelper<
  Mutation,
  MutationVariables extends OperationVariables,
> {
  isLoading = new BooleanState(false);

  constructor(
    private document: TypedDocumentNode<Mutation, MutationVariables>,
  ) {
    makeObservable(this);
  }

  @action async mutate(
    variables: MutationVariables,
    context?: Omit<
      MutationOptions<Mutation, MutationVariables, DefaultContext>,
      "mutation" | "variables"
    >,
  ): Promise<[Mutation, null] | [null, readonly GraphQLError[] | Error]> {
    this.isLoading.on();

    try {
      const { data, errors } = await client.mutate<Mutation, MutationVariables>(
        {
          ...context,
          mutation: this.document,
          variables: variables,
        },
      );

      if (errors !== undefined) {
        return [null, errors];
      }

      if (!data) {
        throw new Error();
      }

      return [data, null];
    } catch (e) {
      console.error(e);
      return [null, e as Error];
    } finally {
      this.isLoading.off();
    }
  }
}
